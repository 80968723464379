.project {
  padding-top: 2rem;
  padding-bottom: 2rem;
  .main-section {
    @include border-block;
    display: flex;
    justify-content: space-between;
    .image-side {
      width: 30%;
    }
    .text-side {
      width: 65%;
    }

    @include breakpoint(small down) {
      .image-side {
        width: 100%;
        text-align: center;
      }
      .text-side {
        width: 100%;
      }
      flex-direction: column;
    }
  }
  .button.large {
    text-transform: uppercase;
    font-weight: bold;
  }

  .center {
    text-align: center;
  }
}
