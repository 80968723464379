$width-max: 1295px;
$spacer: 3rem;
$spacer-small: 1rem;
$spacer-tiny: 0.5rem;
$color-fadedtext: #919191;
$color-green: #32594b;
$body-font-family: "Lato", sans-serif;

$grid-container-padding: (
  small: 200px,
  medium: 300px
);

$foundation-palette: (
  primary: #32594b,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);
