.contact {
  padding-top: 2rem;
  padding-bottom: 2rem;
  .contact-section {
    @include border-block;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .person-contact {
      width: 33%;
      padding-bottom: 1rem;
      .person-name {
        font-weight: bold;
        font-size: 150%;
        color: $color-green;
      }
      .person-title {
        font-weight: bold;
        text-transform: uppercase;
      }

      @include breakpoint(medium down) {
        width: 50%;
      }
      @include breakpoint(small down) {
        width: 100%;
      }
    }
  }
}
