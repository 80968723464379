.header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  background: white;

  border-bottom: 1px solid #d5d5d5;
  .title-area {
    img {
      width: auto;
      height: 130px;
    }
    align-self: flex-end;
  }
  .menu {
    align-self: flex-end;
    color: $color-green;
    a {
      color: $color-green;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .contact {
    color: $color-green;
    align-self: flex-end;
    font-weight: bold;
    a {
      color: $color-green;
    }
    margin-right: 50px;
  }
  @include breakpoint(medium down) {
    .contact {
      margin-right: 10px;
    }
    .menu {
      .hideonmedium {
        display: none;
      }
    }
  }
  @include breakpoint(small down) {
    .title-area {
      img {
        width: auto;
        height: 50px;
      }
      align-self: flex-end;
    }
    .menu {
      align-self: center;
    }
    .contact {
      display: none;
    }
  }
}
