@import "./config/config";
@import "./vendor/normalize";
@import "./config/foundation";
@import "./vendor/foundation/scss/foundation.scss";

@include foundation-global-styles;
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
//@include foundation-callout;
//@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@import "./reusable/typography";
@import "./reusable/utils";
@import "./home/showlist/showlist";
@import "./project/project";
@import "./contact/contact";
@import "./sections/footer/footer";
@import "./sections/header/header";

#body {
  opacity: 1;
  transition: 0.5s ease-out opacity;
  min-height: calc(100vh - 100px);
  background-color: #f0f2f2;
}

.box {
  @include border-block;

  &.first {
    margin-top: 2rem;
  }
}
.about-shows {
  margin-left: auto;
  margin-right: auto;
}
.filters {
  text-align: center;
}
.project .box.first {
  img {
    max-width: 200px;
  }
}
.featherlight.featherlight-iframe {
  .featherlight-content {
    position: relative;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    overflow: auto;
    border-top: 0px solid transparent;
    max-height: 95%;
    margin: 0 0;
    background: #fff;
    cursor: auto;
    white-space: normal;
    @include responsive-embed($ratio: widescreen);
    width: 100%;
    iframe {
      width: 100vw;
    }
  }
}
