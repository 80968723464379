.showlist {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .show-block {
    max-width: 240px;
    img {
      width: 100%;
      height: 100%;
      transition-duration: 0.5s;
      transition-property: transform;
    }
    &:hover {
      img {
        transform: scale(1.3);
      }
    }
  }
  &.small {
    .show-block {
      max-width: 150px;
    }
  }

  a {
    transition: flex 0.5s ease-out;
  }
  &.full {
    a.split {
      flex: 0;
    }
  }
  &.single {
    a.full {
      flex: 0;
    }
  }
}
